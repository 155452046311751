import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DynamicView from "../views/PageViews.vue";
import DynamicView2 from "../views/OperationView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/operation/:id",
    name: "item",
    component: DynamicView2,
  },

  {
    path: "/page/:id",
    name: "item",
    component: DynamicView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
