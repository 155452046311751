<template>
  <main class="absolute w-full h-full inset-0"></main>
</template>
<script>
export default {
  name: "App",
  mounted() {
    window.location.href = "/";
  },
};
</script>
