<template>
  <main class="w-full h-full md:flex md:justify-center md:items-center">
    <div
      v-if="operation && operation != '0' && visible"
      class="iframe fixed inset-0 top-0 left-0 h-full w-full bg-white z-50"
    >
      <iframe
        height="100%"
        class="w-full h-full border-none"
        :src="`https://odr.promo.dev/${operation}#form`"
      ></iframe>
    </div>
    <div class="hidden md:block background fixed z-10"></div>
    <div
      class="w-auto lg:w-full relative z-20 bg-white m-auto flex flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap justify-between md:max-w-[500px] lg:max-w-6xl md:mx-8 xl:mx-0 xl:max-w-7xl lg:shadow-xl lg:rounded-xl lg:overflow-auto"
    >
      <div class="w-full lg:w-[530px] p-4 text-[16px] text-center">
        <img
          src="@/assets/logo.png"
          class="w-[50%] sm:w-[200px] lg:w-[80%] m-auto sm:mb-12 lg:mb-0 mt-[7%]"
        />

        <p class="my-[12%] sm:my-[29px] lg:my-[12%]">
          <span
            class="text-[8vw] leading-[1.7vw] sm:text-4xl lg:text-[4vw] xl:text-[4em] sm:leading-4 lg:leading-[1.2vw] xl:lg:leading-[.7em]"
            >PLUS</span
          ><br />
          <span
            class="text-[5vw] leading-[1.7vw] sm:text-xl lg:text-[1.5vw] sm:leading-4 lg:leading-[1.7vw] xl:text-[1.5em] xl:leading-[.7em]"
            >DE CADEAUX AVEC</span
          ><br />
          <span
            class="text-[14vw] leading-[9vw] sm:text-6xl lg:text-[6vw] sm:leading-10 lg:leading-[4.5vw] xl:text-[5em] xl:leading-[.7em] font-black"
            >FORCE</span
          ><br />
        </p>

        <p
          class="text-[3.5vw] sm:text-base lg:text-[1.2vw] xl:text-[.9em] w-full text-left px-[1em]"
        >
          <!-- <span
            class="font-bold block py-[5px] lg:py-[.5vw] xl:py-[.2em] uppercase"
            >Sélectionnez une offre :</span
          > -->
          <select
            v-model="operation"
            class="border uppercase border-green-500 text-green-700 bg-green-50 w-full p-[1vw] sm:p-[.5vw] xl:p-[.5em] rounded-[.5em] font-semibold"
          >
            <option value="0">Séléctionnez une opération</option>
            <option value="672341d5304a250e98c892fd">
              le 3e produit remboursé - Orange
            </option>
            <option value="672342b8304a250e98c8c643">
              le 3e produit remboursé - autre enseignes
            </option>
            <option value="67234599304a250e98c9358d">
              30&euro; - 50 &euro; REMBOURSÉS
            </option>
          </select>
        </p>
        <div
          v-if="operation !== '0'"
          class="text-[5vw] leading-[6vw] sm:text-lg lg:text-[1.5vw] xl:text-[1.4em] sm:leading-6 lg:leading-[1.2em] font-semibold mt-3 lg:mt-[1em] text-gray-700 bg-gray-100 rounded-[.5em] p-[2vw] sm:p-[10px] lg:p-[1.5vw] xl:p-[1em] mx-4 lg:mx-[1vw] xl:mx-[.8em]"
        >
          <p v-if="operation === '672341d5304a250e98c892fd'">
            Du 15 Novembre 2024 <br />au 15 Février 2025
          </p>
          <p v-if="operation === '67234599304a250e98c9358d'">
            Du 18 Novembre 2024 <br />au 13 Janvier 2025
          </p>
          <p v-if="operation === '672342b8304a250e98c8c643'">
            Du 18 Novembre 2024 <br />au 13 Janvier 2025
          </p>
        </div>
        <p v-if="operation !== '0'">
          <button
            @click="goToOperation"
            class="bg-[#232B34] text-white text-[6vw] sm:text-xl lg:text-[2vw] xl:text-[1.7em] px-4 lg:px-[2vw] xl:px-[1em] py-[1vw] sm:py-1 lg:py-[.4vw] xl:py-[.2em] rounded-[.2em] my-[12vw] sm:my-10 lg:my-[4vw] xl:my-[2em]"
          >
            JE PARTICIPE
          </button>
        </p>

        <p
          class="text-[3vw] sm:text-xs lg:text-[1.1vw] xl:text-[.8em] w-fit text-left pl-4 lg:pl-[2vw] xl:pl-[2em] absolute bottom-1 lg:bottom-[1vw] xl:bottom-[1em] left-[1vw] xl:left-[1em] italic text-gray-600"
          v-if="operation === '672341d5304a250e98c892fd'"
        >
          * le moins cher des 3 plus chers remboursé
        </p>

        <p
          class="text-[3vw] sm:text-xs lg:text-[1.1vw] xl:text-[.8em] w-fit text-left pl-4 lg:pl-[2vw] xl:pl-[2em] absolute bottom-1 lg:bottom-[1vw] xl:bottom-[1em] left-[1vw] xl:left-[1em] italic text-gray-600"
          v-if="operation === '672342b8304a250e98c8c643'"
        >
          * le moins cher des 3 plus chers remboursé
        </p>
        <p
          class="text-[3vw] sm:text-xs lg:text-[1.1vw] xl:text-[.8em] w-fit text-left pl-4 lg:pl-[2vw] xl:pl-[2em] absolute bottom-1 lg:bottom-[1vw] xl:bottom-[1em] left-[1vw] xl:left-[1em] italic text-gray-600"
          v-if="operation === '67234599304a250e98c9358d'"
        >
          * 30 &euro; remboursés dés 99&euro; d'achat, 50 &euro; remboursés dés
          149&euro; d'achat
        </p>
      </div>
      <div><img src="@/assets/ban.jpg" class="w-full lg:w-auto" /></div>
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
// import { useRouter } from "vue-router/composables";
// const router = useRouter();
let visible = ref(false);

let operation = ref("0");

const goToOperation = () => {
  visible.value = true;
};
</script>
